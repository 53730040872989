<template>
  <nav class="align-center">
    <!-- app bar start -->
    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="#9C27B0"
      dark
    >
      <v-toolbar-title style="width: 300px" class="ml-0 pl-3">
        <v-app-bar-nav-icon
          class="mr-2"
          @click.stop="drawer = !drawer"
          v-if="isLoggedIn"
        ></v-app-bar-nav-icon>
        <span
          @click="goToHome()"
          class="hidden-sm-and-down cursor-pointer"
          style="cursor: pointer"
          >AVOCET -WEB</span
        >
      </v-toolbar-title>

      <v-btn icon @click="toggleFullScreen">
        <v-icon>
          {{ this.isFullScreenMode ? "mdi-fullscreen-exit" : "mdi-fullscreen" }}
        </v-icon>
      </v-btn>

      <v-spacer></v-spacer>

      <v-badge
        v-if="this.isAdmin && isLoggedIn"
        floating
        :color="this.invites.length > 0 ? 'green' : '#9C27B0'"
        :content="this.invites.length"
      >
        <v-icon @click="getInites" size="x-large">mdi-store</v-icon>
      </v-badge>

      <v-spacer></v-spacer>

      <v-autocomplete
        v-if="isLoggedIn"
        v-model="company"
        hide-details
        solo-inverted
        :items="userCompanies"
        item-text="name"
        label="Companies"
        return-object
        @change="companyDetails(company)"
      >
        <template slot="no-data">
          <v-btn
            color="#9C27B0"
            dark
            :to="`/company/`"
            class="ml-2 mt-2 mb-2"
            small
            >Create Company</v-btn
          >
          <v-spacer></v-spacer>
        </template>
      </v-autocomplete>

      <v-spacer></v-spacer>
      <clock></clock>

      <v-spacer></v-spacer>

      <v-menu :close-on-content-click="true" offset-y>
        <template v-slot:activator="{ on }">
          <v-col>
            <v-btn fab icon v-on="on">
              <v-icon>mdi-apps</v-icon>
            </v-btn>
          </v-col>
        </template>

        <v-card width="200" max-height="400">
          <v-card-text align="center" justify="center">
            <v-sheet>Apps</v-sheet>
            <v-divider></v-divider>

            <v-row
              ><v-col>
                <v-btn icon @click="gotToWebSite('audit')">
                  <v-icon>mdi-calendar-multiple</v-icon>
                </v-btn>
                <p @click="gotToWebSite('audit')">Organizer</p>
              </v-col>
              <v-col justify="center" align="center">
                <v-btn icon @click="gotToWebSite('openbag')">
                  <v-icon>mdi-cart-outline</v-icon>
                </v-btn>
                <p @click="gotToWebSite('openbag')">Open Bag</p>
              </v-col>
            </v-row>
          </v-card-text></v-card
        >
      </v-menu>

      <v-menu :nudge-width="400" offset-y v-if="isLoggedIn">
        <template v-slot:activator="{ on }">
          <v-btn fab icon v-on="on">
            <v-avatar size="45" item>
              <img v-bind:src="userImage" />
            </v-avatar>
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-img :src="this.userImage"></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title></v-list-item-title>
                <v-list-item-title
                  ><strong class="text-capitalize">{{ this.userName }}</strong
                  ><v-spacer></v-spacer>Emp Id:<strong>{{
                    this.userId
                  }}</strong></v-list-item-title
                >
                <v-list-item-title>{{ this.userEmail }}</v-list-item-title>
                <v-list-item-title>{{ this.userRole }}</v-list-item-title>
                <v-list-item-title
                  ><Strong>Vendor Id:</Strong
                  >{{ this.vendorId }}</v-list-item-title
                >
              </v-list-item-content>
              <!-- v-if="this.userEmail == 'admin@avocet.com'" -->
              <v-list-item-action>
                <v-btn
                  icon
                  color="#2F0A23"
                  v-if="this.userRole == 'ADMIN'"
                  dark
                  to="/avocet-console"
                >
                  <v-icon>mdi-console</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-card-actions>
            <v-btn small text color="#9C27B0" dark to="/profile"
              >View Profile</v-btn
            >

            <v-spacer></v-spacer>
            <v-btn small text color="#9C27B0" dark @click="logout"
              >Logout</v-btn
            >
          </v-card-actions>
          <v-divider></v-divider>

          <v-card-actions v-if="isAdmin">
            <v-btn small color="#9C27B0" dark :to="`/company/`"
              >Create Company</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              small
              v-if="this.userRole == 'ADMIN'"
              color="#9C27B0"
              dark
              :to="`/site-settings/1`"
              >Role Settings</v-btn
            >
          </v-card-actions>

          <v-divider></v-divider>
          <v-list>
            <v-list-item
              v-for="item in this.userCompanies"
              :key="item.id"
              @click="companyDetails(item)"
              :to="`/company-settings/${item.id}`"
            >
              <v-list-item-title class="text-capitalize">{{
                item.name
              }}</v-list-item-title>
              <v-btn
                :class="fav ? '#9C27B0--text' : ''"
                icon
                @click="fav = !fav"
              >
                <v-icon>mdi-wrench</v-icon>
              </v-btn>
              {{ item.id }}
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>

      <v-btn v-else text small class="#4A4066 pr-2" to="/login">LogIn</v-btn>
      <v-btn text small v-if="!isLoggedIn" to="/register">Register</v-btn>
    </v-app-bar>
    <!-- app bar end -->
    <v-navigation-drawer
      :width="250"
      v-model="drawer"
      v-if="isLoggedIn && isAvocetView"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
      color="#9C27B0"
      dark
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6 #BC8749--text text-capitalize">
            {{ this.userName }}
          </v-list-item-title>
          <v-list-item-subtitle class="text-capitalize">
            Logged In as : {{ this.userRole }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <!-- tree view start -->
      <v-treeview
        :items="menus"
        item-text
        item-key="id"
        item-children="childrenItems"
        :treeview-node-height="'40px'"
        :open-on-click="true"
        :activatable="true"
        hoverable
        :return-object="true"
        @update:active="naviagte"
        color="#FFFFFF"
        dark
      >
        <template v-slot:prepend="{ item, open }">
          <div v-if="(item && item.childrenItems).length == 0">
            <v-icon color="#12B8F1"> {{ item.icon }} </v-icon>&nbsp;
            <span>{{ item.name }}</span>
          </div>
          <div v-else>
            <!-- color="green" -->
            <v-icon color="#66BB6A">
              {{ open ? "mdi-book-open-page-variant" : "mdi-book" }} </v-icon
            >&nbsp;
            <span>{{ item.name }}</span>
          </div>
        </template>
      </v-treeview>
      <!-- tree view end -->

      <template v-slot:append>
        <div class="pa-2">
          <v-btn block @click="logout"> Logout </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import clock from "../components/realTimeClock.vue";
export default {
  components: {
    clock,
  },

  data: () => ({
    invites: [],
    isFullScreenMode: false,
    isTallyView: false,
    isAvocetView: true,
    isNewCompany: false,
    company: "",
    companyName: "",
    companyId: "",
    status_code: "",
    fav: true,

    message: false,
    hints: true,
    companies: [],
    current_user: [],
    drawer: false,

    groups: [],
    menus: [],
    auth_token: "",
    user: "",
    userId: "",
    userName: "",
    userEmail: "",
    userRole: "",
    vendorId: "",
    isAdmin: false,
    userProfilePic: "",
    ledgerDetails: "",
    userCompanies: [],
    getIsloggedIn: "",
    dialog3: false,
    userImage:
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAApgAAAKYB3X3/OAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAANCSURBVEiJtZZPbBtFFMZ/M7ubXdtdb1xSFyeilBapySVU8h8OoFaooFSqiihIVIpQBKci6KEg9Q6H9kovIHoCIVQJJCKE1ENFjnAgcaSGC6rEnxBwA04Tx43t2FnvDAfjkNibxgHxnWb2e/u992bee7tCa00YFsffekFY+nUzFtjW0LrvjRXrCDIAaPLlW0nHL0SsZtVoaF98mLrx3pdhOqLtYPHChahZcYYO7KvPFxvRl5XPp1sN3adWiD1ZAqD6XYK1b/dvE5IWryTt2udLFedwc1+9kLp+vbbpoDh+6TklxBeAi9TL0taeWpdmZzQDry0AcO+jQ12RyohqqoYoo8RDwJrU+qXkjWtfi8Xxt58BdQuwQs9qC/afLwCw8tnQbqYAPsgxE1S6F3EAIXux2oQFKm0ihMsOF71dHYx+f3NND68ghCu1YIoePPQN1pGRABkJ6Bus96CutRZMydTl+TvuiRW1m3n0eDl0vRPcEysqdXn+jsQPsrHMquGeXEaY4Yk4wxWcY5V/9scqOMOVUFthatyTy8QyqwZ+kDURKoMWxNKr2EeqVKcTNOajqKoBgOE28U4tdQl5p5bwCw7BWquaZSzAPlwjlithJtp3pTImSqQRrb2Z8PHGigD4RZuNX6JYj6wj7O4TFLbCO/Mn/m8R+h6rYSUb3ekokRY6f/YukArN979jcW+V/S8g0eT/N3VN3kTqWbQ428m9/8k0P/1aIhF36PccEl6EhOcAUCrXKZXXWS3XKd2vc/TRBG9O5ELC17MmWubD2nKhUKZa26Ba2+D3P+4/MNCFwg59oWVeYhkzgN/JDR8deKBoD7Y+ljEjGZ0sosXVTvbc6RHirr2reNy1OXd6pJsQ+gqjk8VWFYmHrwBzW/n+uMPFiRwHB2I7ih8ciHFxIkd/3Omk5tCDV1t+2nNu5sxxpDFNx+huNhVT3/zMDz8usXC3ddaHBj1GHj/As08fwTS7Kt1HBTmyN29vdwAw+/wbwLVOJ3uAD1wi/dUH7Qei66PfyuRj4Ik9is+hglfbkbfR3cnZm7chlUWLdwmprtCohX4HUtlOcQjLYCu+fzGJH2QRKvP3UNz8bWk1qMxjGTOMThZ3kvgLI5AzFfo379UAAAAASUVORK5CYII=",

    tallyMenus: [
      {
        id: 1,
        name: "Gateway of Tally",
        children: [
          { id: 2, name: "Groups", path: "/tally-groups" },
          { id: 3, name: "Ledgers", path: "/tally-ledgers" },
          { id: 4, name: "Vouchers", path: "/vch-tally-sales" },
        ],
      },
    ],
  }),

  created() {
    this.initialize();
    this.getInvites();
  },

  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
  },
  watch: {
    isLoggedIn: function (val) {
      this.getUserProfileData();
      this.getInvites();
    },
    isNewCompany: function (val) {
      this.getUserProfileData();
    },
  },

  methods: {
    goToHome() {
      if (!this.isLoggedIn) {
        this.$router.push("/").catch(() => {});
      } else if (this.$router.history.current.name != "Dashboard") {
        this.$router.push({
          name: `Dashboard`,
        });
      }
    },
    getInites() {
      this.$router.push("/invites").catch(() => {});
    },
    toggleFullScreen() {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
      } else {
        document.exitFullscreen();
      }
      this.isFullScreenMode = !this.isFullScreenMode;
    },

    naviagte(i) {
      if (i.length > 0) {
        if (this.$route.path !== `/${i[0]["path"]}/${i[0]["id"]}`) {
          this.$router.push(`${i[0]["path"]}/${i[0]["id"]}`).catch(() => {});
        }
      }
    },
    gotToWebSite(site) {
      window.open(`http://${site}.opennidus.com`, "_blank");
    },
    shiftToTally() {
      this.isTallyView = true;
      this.isAvocetView = false;
      this.drawer = true;
    },

    shiftToAvocet() {
      this.isTallyView = false;
      this.isAvocetView = true;
      this.drawer = true;
    },
    getUserProfileData() {
      var userId = localStorage.getItem("id");
      this.$http
        .get("/getbyid", {
          params: {
            id: userId,
          },
        })
        .then((response) => {
          this.current_user = response.data;
          (this.userId = this.current_user.user.id),
            (this.userName = this.current_user.user.appUserName);
          this.userEmail = this.current_user.user.email;
          this.userRole = this.current_user.user.appUserRole;
          this.vendorId = this.current_user.user.vendorId;
          this.menus = this.current_user.userMenu;
          this.ledgerDetails = this.current_user.ledgerDetails;
          this.userCompanies = this.current_user.companies;
          localStorage.setItem("vendorId", this.vendorId);
          if (this.userRole == "ADMIN") {
            this.isAdmin = true;
          } else {
            this.isAdmin = false;
          }
        })
        .catch((err) => {
          this.status_code = err.response ? err.response.data.status : {};

          if (this.status_code == 401) {
            this.logout();
          }
        });

      this.$http
        .get("appusers/profile/image/raw?userId=" + userId)
        .then((response) => {
          this.userImage = response.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.loading = false));

      this.dialog = true;
    },

    initialize() {
      var userId = localStorage.getItem("id");
      this.companyId = localStorage.getItem("orgId");

      this.$root.$on("isNewCompany", (isNewCompany) => {
        this.isNewCompany = isNewCompany;
      });

      this.$root.$on("companyId", (companyid) => {
        this.getInvites();
        this.companyId = companyid;
        this.$http
          .get("/open/org/" + this.companyId)
          .then((response) => {
            this.company = response.data;
            this.$store.commit("setCurrency", this.company.currency_type);
          })
          .catch((err) => {});
      });

      this.$http
        .get("/open/org/" + this.companyId)
        .then((response) => {
          this.company = response.data;
          this.$store.commit("setCurrency", this.company.currency_type);
        })
        .catch((err) => {});

      this.getUserProfileData();
    },
    getCompayDetails(company) {
      // alert(company.name + "/" + company.id);
    },

    getInvites() {
      var toVendorId = localStorage.getItem("vendorId");
      var toOrgId = localStorage.getItem("orgId");

      this.$http
        .get("/vendor/invite/get/" + toVendorId + "/" + toOrgId)
        .then((response) => {
          this.invites = response.data;
        })
        .catch((err) => {});
    },

    companyDetails(company) {
      this.company = company;
      this.companyName = company.name;
      this.companyId = company.id;
      localStorage.setItem("orgId", company.id);
      this.$root.$emit("companyId", company.id);
    },

    logout: function () {
      (this.current_user = ""),
        (this.drawer = ""),
        (this.groups = ""),
        (this.menus = []),
        (this.auth_token = ""),
        (this.user = ""),
        (this.userName = ""),
        (this.userEmail = ""),
        (this.userRole = ""),
        (this.ledgerDetails = ""),
        (this.getIsloggedIn = ""),
        (this.company = "");
      this.invites = "";
      localStorage.setItem("orgId", 0);

      this.$store.dispatch("logout").then(() => {
        (this.current_user = []),
          (this.groups = []),
          this.$router.push("/").catch(() => {});
      });
    },

    home: function () {
      this.drawer = false;
      this.$router.push("/dashboard/:id").catch(() => {});
    },
  },
};
</script>

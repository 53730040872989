<template>
  <form class="login" @submit.prevent="login">
    <v-main>
      <v-container fluid fill-height>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <!-- <v-layout align-center justify-center>
            <v-flex xs12 sm8 md4>-->
            <v-card class="elevation-12">
              <v-toolbar dark color="#691883">
                <v-toolbar-title>Login </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom></v-tooltip>
              </v-toolbar>
              <v-card-text>
                <v-form ref="form" v-model="loginValid" lazy-validation>
                  <v-text-field
                    required
                    :rules="validateRules.email"
                    v-model="email"
                    prepend-icon="mdi mdi-user"
                    name="User Name"
                    label="Email"
                    type="text"
                  ></v-text-field>
                  <v-text-field
                    required
                    :rules="validateRules.password"
                    v-model="password"
                    v-on:keyup.enter="login"
                    type="password"
                    id="password"
                    prepend-icon="mdi-lock"
                    name="password"
                    label="Password"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#691883" small dark @click="validate">
                  <v-progress-circular
                    v-if="loading"
                    indeterminate
                    :size="20"
                    :width="1"
                    color="white"
                  ></v-progress-circular>
                  <span v-if="loading">&nbsp;&nbsp;Loading...</span>
                  <span v-if="!loading">Login</span>
                </v-btn>
                <!-- <v-btn to="/register">Register</v-btn> -->
              </v-card-actions>
            </v-card>
            <div>
              <v-snackbar
                absolute
                top
                color="alert"
                outlined
                v-model="snackbar"
              >
                <strong> {{ text }}</strong>
              </v-snackbar>
              <!-- <v-snackbar
                color="light-blue"
                class="text --black"
                top
                v-model="snackbar"
                dark
              >
                {{ text }}
              </v-snackbar> -->
            </div>
          </v-col>
        </v-row>
        <!-- </v-flex>
        </v-layout>-->
      </v-container>
    </v-main>
  </form>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      snackbar: false,
      loginValid: false,
      text: `Welcome`,
      email: "",
      password: "",
      error: "",
      validateRules: {
        email: [(v) => !!v || "Email is required"],
        password: [(v) => !!v || "Password is required"],
      },
    };
  },
  methods: {
    validate() {
      if (!this.loading)
        if (this.$refs.form && this.$refs.form.validate()) this.login();
    },
    login: function () {
      let email = this.email;
      let password = this.password;
      this.loading = true;
      this.$store
        .dispatch("login", { email, password })
        .then((res) => {
          this.text = `Welcome ${res.data.username}`;
          this.snackbar = true;
          setTimeout(
            function () {
              this.loading = false;
              this.$router.push("/").catch(() => {});
            }.bind(this),
            500
          );
        })
        .catch((e) => {
          this.loading = false;
          if (e && e.code && e.message &&  e.message != '') {
            this.snackbar = true;
            this.text = `${e.message}`;
          }
          if (e &&  e.response && e.response.status && e.response.status === 401) {
            this.snackbar = true;
            this.text = `${e.response.data.message}`;
          }
          if (e &&  e.response && e.response.status && e.response.status === 412) {
            this.snackbar = true;
            this.text = `${e.response.data.message}`;
          }
          if (e &&  e.response && e.response.status && e.response.status === 500) {
            this.snackbar = true;
            this.text = `${e.response.data.message}`;
          }
          if (e &&  e.response && e.response.status && e.response.status === 404) {
            this.snackbar = true;
            this.text = `${e.response.data.message}`;
          }
        });
    },
  },
};
</script>

//https://jasonwatmore.com/post/2018/07/14/vue-vuex-user-registration-and-login-tutorial-example
